:root {
  --gold: #a4800f;
  --lightgold: #d9aa16;
  --green: #6b802a;
  --darkgreen: #3b4a07;
}

body {
  background-image: url("./assets/bg2.jpg");
  background-position: center;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

h1 {
  text-align: center;
  color: var(--green);
  font-size: 40px;
  margin-bottom: 20px;
  padding-top: 20px;
}

h2,
.addSignupPlus {
  font-size: 30px;
  font-weight: bold;
}

h3 {
  font-size: 25px;
  font-weight: bold;
}

h4,
legend {
  font-size: 18px;
  font-weight: bold;
  color: var(--gold);
}

label,
.csvLink {
  color: var(--gold);
}

.csvLink {
  font-weight: bold;
}

.copyTag {
  display: none;
}

.generateDiv {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.dishContainer>h4,
.feedbackFormContainer>p {
  text-align: center;
  margin: 20px;
}

.rsvpDataCounts {
  display: flex;
  flex-direction: row;
}

.feedbackFormContainer>p {
  display: none;
}

.feedbackCommentsContainer {
  text-align: center;
  padding: 10px;
}

.feedbackCommentsContainer>input {
  width: 80%;
}

.mobileFeedbackLabels {
  text-align: center;
}

.feedbackFormContainer>p {
  color: var(--gold);
}

.header {
  color: var(--gold);
  font-size: 21px;
}

.header,
.navHeader {
  margin: 20px;
}

.miqaatText {
  font-weight: bold;
}

.sectionHeader,
.feedbackSectionHeader,
.zsSectionHeader,
.yourSignupsHeader {
  background-color: var(--gold);
  padding: 5px;
  width: 100%;
  color: white;
  text-align: center;
  margin: 0px -10px 0px -10px;
}

.eventFormSectionHeader {
  background-color: var(--gold);
  padding: 5px;
  width: 100%;
  color: white;
  text-align: center;
  margin: 0px -10px 0px -10px;
}


.yourSignupsDesktopHeader {
  color: var(--green);
  display: none;
}

.navLink {
  font-size: 25px;
  font-weight: 100;
  color: white;
  text-decoration: none;
  margin: 10px;
}

.navLinks>ul>ul {
  padding-left: 0px;
}

.signupLogin {
  display: flex;
  justify-content: space-between;
  margin: 30px 10px 30px 10px;
}

p {
  font-size: 18px;
}

.allDishes {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rsvpDataCounts {
  display: flex;
  flex-direction: column;
}

button>p {
  font-size: 18px;
  font-weight: bold;
}

button>h2,
.loginForm>form>label {
  font-size: 25px;
  font-weight: bold;
  color: var(--gold);
}

.inviteInstructions {
  color: var(--green);
  align-items: center;
  background-color: white;
  margin: 15px;
  border-radius: 15px;
  padding: 10px;
}

.inviteeCounts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.inviteeCounts>label {
  font-size: 20px;
  font-weight: bold;
  color: var(--gold);
}

.inviteeCounts input {
  width: 40px;
  margin: 10px;
}

.countsError {
  color: var(--green);
}

.checkbox-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  /* gap: 10px; */
  margin: 10px 0;
}

.checkbox-container input {
  transform: scale(1.2);
}

.sizeButtons {
  font-size: 18px;
  font-weight: bold;
  background-color: transparent;
  color: var(--gold);
  border: solid 2px var(--lightgold);
  border-radius: 15px;
  padding: 10px 25px 10px 25px;
  margin: 5px;
}

.sizeButtons:active,
.sizeButtons:hover {
  background-color: var(--lightgold);
  color: white;
  cursor: pointer;
}

.logo {
  background-image: url("./assets/logo.png");
  width: 390px;
  height: 157px;
  margin: 30px;
}

.loginForm>form {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.loginForm,
.addInviteeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginButton {
  align-self: center;
}

input,
select {
  border: 2px solid var(--gold);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  background-color: white;
}

.createMenuForm {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.createEventForm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
}

.eventForm {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
}

.eventForm label,
.eventRsvpForm label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.eventForm input,
.eventForm select,
.eventRsvpForm input,
.eventRsvpForm select {
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.eventRsvpForm select {
  width: 150px;
  margin: 5px;
}

.eventForm input[type="date"] {
  padding: 0.8rem;
}

.loginButton.miqaatCreate {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.eventForm button {
  padding: 1rem 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.eventForm button:hover {
  background-color: #0056b3;
}

.eventForm button h3,
.addInviteeForm button {
  margin: 0;
  font-size: 1.2rem;
}

.addInviteeForm button:hover {
  cursor: pointer;
  background-color: white;
  color: var(--darkgreen);
}

.createMenuForm input {
  width: 160px;
}

.createMenuForm select {
  width: 180px;
}

.multiselect-react-dropdown {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
}

.multiselect-react-dropdown .dropdown-list {
  max-height: 200px;
  overflow-y: auto;
}

.multiselect-react-dropdown .dropdown-item {
  padding: 8px;
}

.multiselect-react-dropdown .dropdown-item.selected,
.dropdown-item:hover {
  background-color: var(--gold) !important;
  color: white;
}

.manageEvent .sectionHeader {
  margin-top: 3%;
}

.submitInviteeButton {
  background-color: var(--darkgreen);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.inviteesDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.addInviteeBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitInviteeButton:hover {
  background-color: var(--green);
}

.inviteesList {
  color: #a4800f;
  width: 100%;
}

.inviteesList h3 {
  color: #6b802a;
}

.inviteesList ul {
  list-style-type: none;
  padding: 0;
}

.inviteesList ul li {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 1fr;
  height: 50%;
}

.inviteesList li {
  margin: 5px 0;
  padding: 10px;
  /* border: 1px solid var(--gold); */
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.inviteesList li div {
  margin: auto 0px;
  justify-items: left;
  padding-left: 1%;
}

.inviteesList li .cancelButton {
  border: 2px solid var(--gold);
  font-size: medium;
  padding: 10px;
}

.miqaatCreate {
  display: flex;
  justify-content: center;
}

.hostsDiv {
  display: grid;
  flex-direction: row;
  line-height: 00.75rem;
  text-align: center;
  margin: 10px;
}

.miqaatRowLine {
  width: 100%;
  color: var(--gold);
}

.miqaatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.singleEventContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.miqaatRow {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  width: 70%;
  border-radius: 15px;
}

.createMenuForm input,
.createMenuForm select,
::placeholder,
.addDishForm select {
  color: var(--gold);
}

.errMsg {
  color: var(--gold);
  margin-top: 10px;
}

.forgotPasswordMsg {
  color: var(--gold);
}

.loginForm input,
.addInviteeForm input,
.addInviteeForm select {
  color: var(--green);
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--green);
}

.loginButton>button {
  background-color: var(--green);
  color: white;
  border: 2px solid var(--darkgreen);
  border-radius: 15px;
  margin: 20px;
  padding: 10px 25px 10px 25px;
}

.submitButton,
.ok {
  background-color: var(--green);
  color: white;
  border: 2px solid var(--darkgreen);
  border-radius: 15px;
  padding: 3px 9px 3px 9px;
}

.signupInputs,
.sizeOption,
.dishesRow,
.signupHistoryRow {
  display: flex;
  justify-content: space-between;
}

.grid-container {
  display: none;
}

.grid-item {
  text-align: center;
  color: var(--gold);
}

.feedbackForm {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.feedbackForm input[type="submit"] {
  align-self: center;
}

#feedbackResultsHeader {
  display: none;
}

.feedbackInputs {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.ratingRadioButtons {
  flex-grow: 5;
  display: flex;
  justify-content: space-around;
}

legend {
  flex-shrink: 1;
  min-width: 10%;
  align-self: center;
}

input[type="radio"] {
  margin-right: 10px;
}

.signup {
  min-width: 400px;
  height: 250px;
  margin: 0px 40px 40px 40px;
}

.userSignup {
  min-width: 400px;
  height: 335px;
}

.addButtonAndPhoto,
.buttonAndPhoto {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  position: relative;
  z-index: 1;
  top: -80px;
  bottom: 0px;
}

.addButton,
.editButton,
.cancelButton {
  background-color: white;
  border-radius: 15px;
  color: var(--green);
  align-self: end;
}

.addInviteeButton {
  background-color: var(--gold);
  border: 2px solid var(--gold) !important;
  border-radius: 15px;
  color: white;
  align-self: end;
  padding: 11px 30px;
  font-weight: bold;
  margin-top: 4px;
  font-size: 20px;
}

.addInviteeButton:hover {
  background-color: white;
  color: var(--gold) !important;
}

.addButton {
  width: 40px;
  height: 40px;
  padding: 10px;
}

.cantEditMessage,
.cantCancelMessage {
  position: relative;
  top: -65px;
  left: 20px;
}

.editButton,
.cancelButton {
  padding: 15px;
  font-weight: bold;
}


.addSignupPlus,
.editButtonText,
.cancelButtonText {
  text-align: center;
}

.openSignupInfo,
.yourSignupInfo,
.dishesRow,
.allDishes,
.signupHistoryRow {
  width: 260px;
}

.yourSignups {
  height: 300px;
}

.openSignupInfo,
.yourSignupInfo,
.dishesRow,
.allDishes,
.cookTile,
.signupHistoryInfo,
.signupHistoryRow,
.feedbackInputs {
  background-color: white;
  border-radius: 15px;
  line-height: 2.5em;
  padding: 15px 20px 15px 20px;
  color: var(--gold);
  margin: 15px;
}

.miqaatRow {
  line-height: 2.5em;
  color: var(--gold);
  text-align: center;
}

.miqaatTitle {
  background-color: var(--gold);
  color: white;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.stats {
  color: var(--gold);
}

.modalSuccessful {
  height: 150px;
  width: fit-content;
  background-color: white;
  border-radius: 15px;
  border: 3px solid var(--lightgold);
  line-height: 2.5em;
  padding: 50px;
  color: var(--gold);
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.inviteModalSuccessful {
  height: 400px;
  width: 300px;
  background-color: white;
  border-radius: 15px;
  border: 3px solid var(--lightgold);
  line-height: 2.5em;
  padding: 50px;
  color: var(--gold);
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ok {
  padding: 10px 15px 10px 15px;
}

.modalParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  height: 50vh;
  width: 100vw;
  position: fixed;
  visibility: hidden;
}

.rsvpModalParent,
.inviteModalParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  height: 30vh;
  width: 80vw;
  position: fixed;
  visibility: hidden;
}



.dishPhoto {
  border: 3px solid var(--green);
  border-radius: 15px;
  width: 130px;
  height: 130px;
}

.uploadPhotoForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadPhotoForm b:hover {
  cursor: pointer;
}

.uploadPhotoForm button {
  margin: 20px;
  padding: 10px 25px 10px 25px;
}

.uploadPhotoForm button:hover {
  cursor: pointer;
  background-color: white;
  color: var(--darkgreen);
}

.uploadPhotoForm img {
  box-shadow: none;
}

.cookTiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cookTile {
  display: flex;
  padding: 15px;
  margin: 30px;
  width: 130px;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  text-align: center;
}

.cookTile p {
  font-size: 22px;
}

#addCookPlus,
#addPhotoPlus {
  font-size: 50px;
  margin: 0px;
}

.cookTile:hover,
#addPhotoPlus:hover,
.cancelButton:hover,
.editButton:hover,
.addButton:hover,
.addInviteeButton:hover {
  cursor: pointer;
}

.signupForm {
  visibility: hidden;
  padding-top: 85px;
}

.cookForm {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  visibility: hidden;
}

.cookFormWrapperDiv input {
  margin: 20px;
}

.signupsTable,
.dishesLink {
  margin-bottom: 75px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
}

.signupsTable {
  max-width: 600px;
}

.signupsRow {
  height: 50%;
  min-width: 400px;
}

.navAndHeader {
  display: flex;
}

.dishesRow,
.signupHistoryRow {
  flex-direction: column;
}

.paymentHistoryDiv {
  display: flex;
  justify-content: center;
}

.navHeader {
  color: white;
}

.navigation {
  display: flex;
}

.navigation ul,
.navigationDesktop ul {
  display: flex;
  flex-wrap: wrap;
}

.navigation ul li {
  list-style-type: none;
  margin: 10px 0px 10px 0px;
}

.navigationDesktop ul li {
  list-style-type: none;
}

.navigation ul li h3,
.navigationDesktop ul li h3 {
  padding: 10px 20px 10px 20px;
  font-size: 19px;
}

.navigation ul li h3:hover,
.navigation ul li h3:active,
.navigationDesktop ul li h3:hover {
  background-color: var(--lightgold);
}

.hamburger {
  width: 2rem;
  height: 2rem;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  align-self: center;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: var(--lightgold);
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.addDishForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.addDishForm input,
.createMenuForm select {
  margin-bottom: 10px;
}

.createRsvpForm select {
  width: 130px;
  margin: 5px;
}

.addDishForm input[type="submit"],
.createMenuForm input[type="submit"],
.feedbackButton {
  width: 150px;
  align-self: center;
}

input[type="submit"],
button[type="submit"],
.menuPaidButton,
.returnToPendingButton {
  background-color: var(--green);
  color: white;
  border: 2px solid var(--darkgreen);
  border-radius: 15px;
  font-weight: bold;
  width: fit-content;
  width: -moz-fit-content;
}

.categoriesTab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
}

.categoriesTab div {
  background-color: var(--gold);
  width: 100%;
  text-align: center;
  padding: 8px 22px;
  margin: 0px 0px 3px 0px;
  color: white;
  font-size: 18px;
}

.categoriesTab div:hover {
  background-color: var(--lightgold);
}

.categoriesTab div:first-child {
  border-radius: 15px 15px 0px 0px;
}

.categoriesTab div:last-child {
  border-radius: 0px 0px 15px 15px;
}

.returnToPendingButton {
  padding: 10px 0px 10px 0px;
}

.addCostForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addCostForm input {
  width: 110px;
  font-size: 18px;
}

.amountAndPaidButton {
  display: flex;
  justify-content: space-between;
}

.menuPaidButton {
  width: 80px;
}

.dishRowLine {
  visibility: hidden;
}

#chart {
  max-width: 600px;
}

.feedbackContainer {
  background-color: white;
  padding: 15px 20px 0px 20px;
  margin: 30px;
  border-radius: 15px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feedbackContainer h4 {
  margin-bottom: 20px;
}

@media (max-width: 1258px) {
  .hamburger {
    display: flex;
    margin-left: 10px;
    z-index: 10;
  }

  .navigationDesktop,
  .feedbackContainer,
  .feedbackSectionHeader {
    display: none;
  }
}

@media (min-width: 1258px) {
  .navigationDesktop {
    background-color: var(--gold);
    width: 100%;
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
    height: 47.5px;
    align-items: center;
  }

  .navigation {
    display: none;
  }

  .navHeader {
    font-size: 20px;
  }

  .navAndHeader {
    flex-direction: column;
  }

  .header,
  .yourSignupsHeader {
    text-align: center;
    display: none;
  }

  .signupsContainer,
  .miqaatContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }

  .miqaatContainer {
    width: 80%;
    align-items: stretch;
  }

  .zsSectionHeader {
    padding: 0px;
    margin: 0px;
    border-radius: 15px 15px 0px 0px;

  }

  .sectionHeader,
  .feedbackSectionHeader {
    width: 80%;
    margin: 0px;
    padding: 0px 20px 0px 20px;
    border-radius: 15px 15px 0px 0px;
  }

  .eventFormSectionHeader {
    width: 90%;
    margin: 0px;
    border-radius: 15px 15px 0px 0px;
    padding: 0px 20px 0px 40px;
    border-radius: 15px 15px 0px 0px;
  }

  .feedbackSectionHeader,
  .feedbackContainer {
    display: none;
  }

  .feedbackContainer {
    flex-direction: column;
    align-items: center;
  }

  .categoriesTab {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .categoriesTab div {
    background-color: var(--gold);
    width: 100%;
    text-align: center;
    padding: 8px;
    margin: 0px 5px 0px 0px;
    color: white;
    font-size: 18px;
  }

  .categoriesTab div:hover {
    background-color: var(--lightgold);
  }

  .categoriesTab div:first-child {
    border-radius: 15px 0px 0px 15px;
  }

  .categoriesTab div:last-child {
    border-radius: 0px 15px 15px 0px;
    margin-right: 0px;
  }

  .createMenuForm,
  .addDishForm {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 5px 20px;
    justify-content: space-around;
    margin-top: 0px;
    margin-bottom: 30px;
    border-radius: 0px 0px 15px 15px;
    width: 80%;
  }

  .createMenuForm select,
  .createMenuForm input,
  .addDishForm select,
  .addDishForm input {
    margin: 15px;
  }

  #zoneMembersRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-auto-flow: row;
    padding: 15px;
    width: 90%;
  }

  #allEventsRow {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .miqaatRow {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 10px;
    width: 90%;
    border-radius: 15px;
    line-height: 2.5em;
    color: var(--gold);
    text-align: center;
  }

  #uploadInvite {
    grid-template-columns: 1fr !important;
  }

  #uploadInvite button:hover {
    cursor: pointer;
    background-color: white;
    color: var(--darkgreen);
  }

  .dishesRow {
    grid-template-columns: repeat(3, 1fr);
  }

  .signupHistoryRow {
    grid-template-columns: repeat(4, 1fr);
  }

  .dishesRow,
  .signupHistoryRow {
    display: grid;
    grid-auto-rows: minmax(50px, auto);
    width: 100%;
    padding: 10px 0px;
    margin: 0px;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    align-items: center;
  }

  #cookDishesRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(50px, auto);
  }

  #cookLoginDishesRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(50px, auto);
  }

  #paymentHistoryRo,
  #upcomingDishesRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(50px, auto);
  }

  #eventsRsvpsRow,
  #invitationRow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(50px, auto);
  }

  
  .allDishes {
    background-color: transparent;
    box-shadow: none;
  }

  .dishRowLine {
    visibility: visible;
  }

  input {
    margin-bottom: 0px;
  }

  .dishContainer,
  .feedbackContainer,
  .allDishesContainer {
    width: 80%;
    background-color: white;
    padding: 15px 20px 0px 20px;
    margin-bottom: 30px;
    border-radius: 0px 0px 15px 15px;
  }

  .zoneAndStatsContainer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 80%;
    padding: 0px 20px 0px 20px;
    border-radius: 0px 0px 15px 15px;
  }

  .zoneContainer {
    width: 50%;
    background-color: white;
    border-radius: 15px;
  }

  .thaaliAndTakhmeenStats {
    margin-bottom: 20px;
  }

  #thaaliStats {
    height: fit-content;
    margin-bottom: 0px;
  }

  .statsContainer {
    width: 50%;
  }

  .thaaliAndTakhmeenStats {
    background-color: white;
    border-radius: 15px;
  }

  .zoneContainer,
  .zoneContainer>.dishesRow {
    margin: 0px 20px 0px -20px;
  }

  .zoneContainer {
    height: fit-content;
  }

  .statsContainer {
    margin: 0px -20px 0px 20px;
  }

  .statsContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-self: start;
  }

  .stats,
  .zoneMembersRow {
    padding: 20px;
  }

  .takhmeenStats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
  }

  .feedbackContainer {
    margin-top: 0px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 2fr;
    gap: 10px;
    padding: 20px 0px 0px 0px;
  }

  .feedbackContainer h4 {
    margin-bottom: 20px;
  }

  .rsvpDataCounts {
    display: flex;
    flex-direction: column;
  }

  .allDishesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .yourSignupsDesktopHeader {
    display: block;
  }

  .miqaatText {
    font-weight: normal;
  }

  .feedbackInputs {
    flex-direction: row;
    margin: 0px 20px 20px 20px;
  }

  .mobileFeedbackLabels {
    display: none;
  }

  .feedbackFormContainer>p {
    display: block;
  }

  .inviteeCounts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}